// 引入request请求方法
import request from "@/utils/request";
// 验证码
export default {
    // 获取图形验证码
    getCaptcha(params) {
        return request({
            url: "/guest/user/getCaptcha?uuid=" + params,
            method: "get",
			responseType: "arraybuffer",
        });
    },
    // 登录
    loginSend(params) {
        return request({
            url: "/guest/user/login",
            method: "post",
            params,
        });
    },
    // 退出登录
    logout(params) {
        return request({
            url: "/user/logout",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 注册
    registerSend(params) {
        return request({
            url: "/guest/user/register",
            method: "post",
            params,
        });
    },
    // 获取注册项
    getRegisterItem(params) {
        return request({
            url: "/guest/user/getRegisterItem",
            method: "post",
            data: params,
        });
    },
    // 获取注册项
    getRegisterTerms(params) {
        return request({
            url: "/guest/user/getRegisterTerms",
            method: "post",
            params,
        });
    },
    // 个人资料
    getUserInfo(params) {
        return request({
            url: "/user/getUserInfo",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 公告信息
    getNotice(params) {
        return request({
            url: "/guest/index/getNotice",
            method: "post",
            data: params,
        });
    },
    // VIP最高返水比例
    getVipReturnWaterList(params) {
        return request({
            url: "/guest/user/getVipReturnWaterList",
            method: "post",
            data: params,
        });
    },
    // 获取网站基本信息
    getWebsiteBasicInfo() {
        return request({
            url: "/guest/index/getWebsiteBasicInfo",
            method: "post",
        });
    },
    // 获取VIP提款额度
    getVipInfo(params) {
        return request({
            url: "/guest/user/getVipInfo",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 获取注册项、条款
    getRegisterItem(params) {
        return request({
            url: "/guest/user/getRegisterItem",
            method: "post",
            data: params,
        });
    },
    // 查询用户余额
    getUserBalance(params) {
        return request({
            url: "/user/getUserBalance",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 获取充值帐号信息
    getRechargeAccountInfo(params) {
        return request({
            url: "/recharge/getRechargeAccountInfo",
            method: "post",
            params,
            token: true,
        });
    },

    // 获取充值帐号信息-修改
    getPaymentMethod(params) {
        return request({
            url: "/pay/paymentMethod/getPaymentMethod",
            method: "post",
            data: params,
            token: true,
        });
    },
    getPaymentMethodByType(params) {
        return request({
            url: "/pay/paymentMethod/getPaymentMethodByType",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },

    // 用户充值
    getRecharge(params) {
        return request({
            url: "/recharge/recharge",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 用户提现
    getWithdraw(params) {
        return request({
            url: "/withdraw/withdraw",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 交易详情
    getUserTransDetail(params) {
        return request({
            url: "/user/getUserTransDetail/" + params,
            method: "post",
            token: true,
        });
    },

    // 修改密码
    ModifyPassword(params) {
        return request({
            url: "/user/modifyPassword",
            method: "post",
            params,
            token: true,
        });
    },
    // 是否设置取款密码
    checkIsSetWidthPassword(params) {
        return request({
            url: "/user/checkIsSetWidthPassword",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 获取投注记录
    getBetRecord(params) {
        return request({
            url: "/user/getBetRecord",
            method: "post",
            params,
            token: true,
        });
    },
    // 获取投注记录
    getSumBetRecord(params) {
        return request({
            url: "/user/getSumBetRecord",
            method: "post",
            params,
            token: true,
        });
    },
    // 福利中心列表
    queryWelfareCenterList(params) {
        return request({
            url: "/welfareCenter/queryWelfareCenterList",
            method: "post",
            params,
            token: true,
        });
    },

    // 福利中心总汇
    queryWelfareCenterTotal(params) {
        return request({
            url: "/welfareCenter/queryWelfareCenterTotal",
            method: "post",
            params,
            token: true,
        });
    },
    // 获取用户交易记录
    getUserTransInfo(params) {
        return request({
            url: "/user/getUserTransInfo",
            method: "post",
            params,
            token: true,
        });
    },

    // 获取用户交易记录
    getTransactionRecordInfo(params) {
        return request({
            url: "/transactionRecord/getTransactionRecordInfo",
            method: "post",
            data: params,
            token: true,
        });
    },

    // 取消充值
    cancelRechargeOrder(params) {
        return request({
            url: "/recharge/cancelRechargeOrder/" + params,
            method: "post",
            token: true,
        });
    },

    // 转账前获取转账钱包相关信息
    getTransWalletBalanceInfo(params) {
        return request({
            url: "/user/getTransWalletBalanceInfo",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 额度转换(一键转入/转出)
    getConvertMoney(params) {
        return request({
            url: "/apiCode/convertMoney",
            method: "post",
            params,
            token: true,
        });
    },
    // 一键转出
    getOneTouchTransfer(params) {
        return request({
            url: "/user/oneTouchTransfer",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 查询所有游戏平台类型
    getAllPlatTypes(params) {
        return request({
            url: "/guest/apiCode/getAllPlatTypes",
            method: "post",
            params,
        });
    },
    // 获取游戏
    getGameList(params) {
        return request({
            url: "/guest/apiCode/getGameList",
            method: "post",
            data: params,
        });
    },
    // 根据游戏类型获取游戏列表
    getGameListByGameType(params) {
        return request({
            url: "/apiCode/getGameListByGameType",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 获取游戏地址
    getGameUrl(params) {
        return request({
            url: "/apiCode/getGameUrl",
            method: "post",
            params,
            token: true,
        });
    },
    // 一键刷新(查询所有平台余额)
    getUserPlatBalance(params) {
        return request({
            url: "/apiCode/getUserPlatBalance",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 所有游戏的返水比例
    queryWaterStrategyByGameType(params) {
        return request({
            url: "/strategy/queryWaterStrategyByGameType",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 修改个人资料
    modifyUserInfo(params) {
        return request({
            url: "/user/modifyUserInfo",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 收藏
    setGameCollect(params) {
        return request({
            url: "/gameCollect/save",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 取消收藏
    canlceGameCollect(params) {
        return request({
            url: "/gameCollect/delete/1",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 图片上传
    uploadFile(params) {
        return request({
            url: "/upload/uploadFile",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 我的反馈列表
    getFeedbackList(params) {
        return request({
            url: "/feedback/list",
            method: "post",
            params,
            token: true,
        });
    },
    // 我的反馈详情
    feedbackDetail(params) {
        return request({
            url: "/feedback/info/" + params,
            method: "post",
            token: true,
        });
    },
    // 删除我的反馈
    deleteFeedback(params) {
        return request({
            url: "/feedback/delete/" + params,
            method: "post",
            token: true,
        });
    },
    // 意见反馈
    submitFeedback(params) {
        return request({
            url: "/feedback/save",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 所有银行
    getSysBank(params) {
        return request({
            url: "/sys/sysbank/getSysBank",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 绑定银行卡
    bindBankCard(params) {
        return request({
            url: "/userBankCard/bindBankCard",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },

    // 银行卡列表
    queryUserBankCardList(params) {
        return request({
            url: "/userBankCard/queryUserBankCardList",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 删除银行卡
    deleteBankCard(params) {
        return request({
            url: "/userBankCard/deleteBankCard/" + params,
            method: "post",
            token: true,
        });
    },
    // 修改银行卡
    modifyBindBankCard(params) {
        return request({
            url: "/userBankCard/modifyBindBankCard",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 绑定区块链账号
    bindUserBlockchain(params) {
        return request({
            url: "/userBlockchain/bindUserBlockchain",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 修改区块链账号
    modifyUserBlockchain(params) {
        return request({
            url: "/userBlockchain/modifyUserBlockchain",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 删除区块链账号
    deleteUserBlockchain(params) {
        return request({
            url: "/userBlockchain/deleteUserBlockchain/" + params,
            method: "post",
            token: true,
        });
    },
    // 获取区块链账号列表
    queryUserBlockchainList(params) {
        return request({
            url: "/userBlockchain/queryUserBlockchainList",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 绑定第三方账户
    bindThirdPartAccount(params) {
        return request({
            url: "/userThirdPartAccount/bindThirdPartAccount",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 修改第三方账户
    modifyThirdPartAccount(params) {
        return request({
            url: "/userThirdPartAccount/modifyThirdPartAccount",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 删除已绑定第三方账号
    deleteThirdPartAccount(params) {
        return request({
            url: "/userThirdPartAccount/deleteThirdPartAccount/" + params,
            method: "post",
            token: true,
        });
    },
    // 已绑定第三方账号列表
    queryThirdPartAccountList(params) {
        return request({
            url: "/userThirdPartAccount/queryThirdPartAccountList",
            method: "post",
            params,
            token: true,
        });
    },
    // 查询所有活动分类
    getActivityClassification(params) {
        return request({
            url: "/guest/activity/getActivityClassification",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 查询所有活动
    getActivityList(params) {
        return request({
            url: "/guest/activity/list",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 合同详情
    getActivityDetail(params) {
        return request({
            url: "/guest/activity/getActivityDetail",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 活动申请
    applyActivity(params) {
        return request({
            url: "/activity/activityApply/applyActivity",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 活动申请记录
    queryApplyActivityList(params) {
        return request({
            url: "/activity/activityApply/queryApplyActivityList",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 查询字典
    getSysDictionary(params) {
        return request({
            url: "/common/sysDictionary/getSysDictionary",
            method: "post",
            params,
        });
    },
    // 获取邀请好友配置信息
    getInviterBaseConfig(params) {
        return request({
            url: "/inviter/inviterBaseConfig/getInviterBaseConfig",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 获取前50条邀请好友滚动记录
    getMarqueeInviterRecord(params) {
        return request({
            url: "/inviter/inviterRecord/getMarqueeInviterRecord",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 获取邀请好友奖品配置信息
    queryInviterRecordTotal(params) {
        return request({
            url: "/inviter/inviterRecord/queryInviterRecordTotal",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 获取邀请好友奖品配置信息
    queryInviterPrizeConfigList(params) {
        return request({
            url: "/inviter/inviterPrizeConfig/queryInviterPrizeConfigList",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 获取邀请好友奖品配置信息
    getInviterRebateTotal(params) {
        return request({
            url: "/inviter/inviterRebateRecord/getInviterRebateTotal",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 获取邀请人相关信息
    getInviterInfo(params) {
        return request({
            url: "/user/getInviterInfo",
            method: "post",
            data: params,
            token: true,
        });
    },

    // 邀请好友-邀请记录
    queryPageInviterRecordList(params) {
        return request({
            url: "/inviter/inviterRecord/queryPageInviterRecordList",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },

    // 邀请好友-邀请记录汇总
    queryPageInviterRecordTotal(params) {
        return request({
            url: "/inviter/inviterRecord/queryPageInviterRecordTotal",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },

    // 邀请好友-返利记录
    queryPageInviterRebateList(params) {
        return request({
            url: "/inviter/inviterRebateRecord/queryPageInviterRebateList",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 邀请好友-返利记录汇总
    queryPageInviterRebateTotal(params) {
        return request({
            url: "/inviter/inviterRebateRecord/queryPageInviterRebateTotal",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
            sbjava: true
        });
    },
    // 获取广告信息
    getSilde(params) {
        return request({
            url: "/guest/index/getSilde",
            method: "post",
            params,
            token: true,
        });
    },

    // 获取广告信息
    getMessageList(params) {
        return request({
            url: "/message/list",
            method: "post",
            data: params,
            token: true,
        });
    },

    // 校验用户是否使用过极速取款
    checkIsQuickWithdraw(params) {
        return request({
            url: "/withdraw/checkIsQuickWithdraw",
            method: "post",
            data: params,
            token: true,
        });
    },

    // 获取取款方式
    getWithdrawMethod(params) {
        return request({
            url: "/withdraw/withdrawMethod/getWithdrawMethod",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 发送短信验证码
    getSmsCode(params) {
        return request({
            url: "/sms/getSmsCode",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },

    // 校验短信验证码
    checkSmsCode(params) {
        return request({
            url: "/sms/checkSmsCode",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },

    // 获取邮箱验证码
    getEmailCode(params) {
        return request({
            url: "/email/getEmailCode",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },

    // 校验邮箱验证码
    checkEmailCode(params) {
        return request({
            url: "/email/checkEmailCode",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },

    // 确认充值订单-上传凭证
    confirmRechargeOrder(params) {
        return request({
            url: "/recharge/confirmRechargeOrder",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },

    // 忘记密码第一步
    forgetPasswordFirstStep(params) {
        return request({
            url: "/guest/user/forgetPasswordFirstStep",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 忘记密码获取短信验证码
    getPhoneCodeByForgetPwd(params) {
        return request({
            url: "/guest/user/getPhoneCodeByForgetPwd",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 校验忘记密码短信验证码
    checkPhoneCodeByForgetPwd(params) {
        return request({
            url: "/guest/user/checkPhoneCodeByForgetPwd",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 忘记密码获取邮箱验证码
    getEmailCodeByForgetPwd(params) {
        return request({
            url: "/guest/user/getEmailCodeByForgetPwd",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 校验忘记密码邮箱验证码
    checkPhoneCodeByForgetPwd(params) {
        return request({
            url: "/guest/user/checkPhoneCodeByForgetPwd",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 忘记密码-设置密码
    modifyPasswordByForget(params) {
        return request({
            url: "/guest/user/modifyPasswordByForget",
            method: "post",
            data: params,
            token: true,
            // jsonType: true
        });
    },
    // 热门搜索
    getTop5PopularSearchGame(params) {
        return request({
            url: "/apiCode/getTop5PopularSearchGame",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 消息中心
    getMessageList(params) {
        return request({
            url: "/message/list",
            method: "post",
            params,
            token: true,
        });
    },
    // 消息中心标记已读
    doReadMessage(params) {
        return request({
            url: "/message/doReadMessage",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 消息中心未读数量
    getUnReadMessageCount(params) {
        return request({
            url: "/message/getUnReadMessageCount",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 消息详情
    getMessage(params) {
        return request({
            url: "/message/getMessage/" + params,
            method: "post",
            token: true,
        });
    },

    // 删除
    deleteMessage(params) {
        return request({
            url: "/message/deleteMessage",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 全部已读
    modifyReadAllMessage(params) {
        return request({
            url: "/message/modifyReadAllMessage",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 取款规则
    getWithdrawExtraRule(params) {
        return request({
            url: "/withdraw/withdrawExtraRule/getWithdrawExtraRule",
            method: "post",
            data: params,
            token: true,
        });
    },

    // 取消收藏
    canleceDelete(params) {
        return request({
            url: "/gameCollect/delete/" + params,
            method: "post",
            token: true,
        });
    },

    // 用户充值(易币付支付)
    createOrder(params) {
        return request({
            url: "/yiBiPay/createOrder",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },

    // 佳运支付(ebPay)
    JYpayCreateOrder(params) {
        return request({
            url: '/jiaYunPay/createOrder',
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        })
    },
    // 20231009
    toPaycreateOrder(params) {
        return request({
            url: 'toPay/createOrder',
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        })
    },

    // 中心支付
    getCenterCreateOrder(params) {
        return request({
            url: '/centerPay/createOrder',
            method: 'post',
            data: params,
            token: true,
            jsonType: true
        })
    },

    // 华联支付
    huaLianPayCreateOrder(params) {
        return request({
            url: '/huaLianPay/createOrder',
            method: 'POST',
            data: params,
            token: true,
            jsonType: true
        })
    },
    // 嗨钱包
    hiWalletCreateOrder(params) {
        return request({
            url: '/hiWallet/createOrder',
            method: 'POST',
            data: params,
            token: true,
            jsonType: true
        })
    },
    // 博纳支付
    bnPaycreateOrder(params) {
        return request({
            url: '/bnPay/createOrder',
            method: 'POST',
            data: params,
            token: true,
            jsonType: true
        })
    },
    // hiPay
    hiPaycreateOrder(params) {
        return request({
            url: '/hiPay/createOrder',
            method: 'POST',
            data: params,
            token: true,
            jsonType: true
        })
    },
    // 聚合支付
    juHePaycreateOrder(params) {
        return request({
            url: '/juHePay/createOrder',
            method: 'POST',
            data: params,
            token: true,
            jsonType: true
        })
    },
    // 新诚博付 支付
    xcbfPaycreateOrder(params) {
        return request({
            url: '/xcbfPay/createOrder',
            method: 'POST',
            data: params,
            token: true,
            jsonType: true
        })
    },
    // JJPau 支付
    jPayCreateOrder(params) {
        return request({
            url: '/jPay/createOrder',
            method: 'POST',
            data: params,
            token: true,
            jsonType: true
        })
    },
    // 柏合支付
    baiHePayCreateOrder(params) {
        return request({
            url: '/baiHePay/createOrder',
            method: 'POST',
            data: params,
            token: true,
            jsonType: true
        })
    },
    // 用户充值(ebPay)
    ebPayCreateOrder(params) {
        return request({
            url: "/ebPay/createOrder",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 用户充值(ebPay)
    koiPayCreateOrder(params) {
        return request({
            url: "/koiPay/createOrder",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 公告
    getTop50MessageList(params) {
        return request({
            url: "/message/getTop50MessageList",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },

    // 交易详情接口1
    queryTransactionRecordList(params) {
        return request({
            url: "/transactionRecord/queryTransactionRecordList",
            method: "post",
            params,
            token: true,
        });
    },

    // 查询系统配置参数
    getSysConfigValue(params) {
        return request({
            url: "/sys/sysConfig/getSysConfigValue",
            method: "post",
            params,
            token: true,
        });
    },
    // 校验取款密码
    checkWithdrawPwd(params) {
        return request({
            url: "/user/checkWithdrawPwd",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },
    // 校验取款密码
    changeWalletType(params) {
        return request({
            url: "/user/changeWalletType",
            method: "post",
            data: params,
            token: true,
        });
    },
    // 上传图片接口
    upLoadImgPost(params) {
        return request({
            url: "/upload/uploadFile",
            method: "post",
            data: params,
            token: true,
            isFormData: true,
        });
    },

    // 首页游戏列表
    getApiGameCategoryList(params) {
        return request({
            url: '/guest/api/apiGameCategory/getApiGameCategoryList',
            method: 'post',
            // data: params,
            // token: true,
        })
    },

    // 领取福利
    changeLanguageAndCurrenc(params) {
        return request({
            url: '/user/changeLanguageAndCurrency',
            method: 'POST',
            data: params,
            token: true,
            jsonType: true
        })
    },
    // 领取福利code
    getInviterQrcode(params) {
        return request({
            url: '/user/getInviterQrcode',
            method: 'POST',
            data: params,
            token: true,
            jsonType: true
        })
    },

    // 注册发送短信验证码
    getPhoneCodeByRegister(params) {
        return request({
            url: "/guest/user/getPhoneCodeByRegister",
            method: "post",
            data: params,
            token: true,
            jsonType: true,
        });
    },

    // 领取福利
    getReceiveWelfare(params) {
        return request({
            url: "/welfareCenter/receiveWelfare/" + params,
            method: "post",
            token: true,
        });
    },
    
};